/* .App {
   text-align: center; 
} */

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.navbar {
  width: 100%;
  height: 50px;
  background-color:  #023365;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar .links {
  text-align: center;
  margin-right: 50px;
}

.links a {
  color: white;
  text-decoration: none;
  border-bottom: 3px solid white;
  padding-bottom: 2px;
  margin: 10px;
}

.navbar .user {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
}

.user p {
  font-size: 15px;
  margin-right: 10px;
  color: white;
}
.user img {
  font-size: 15px;
  border-radius: 50%;
}

input,
textarea {
  font-family: "Nunito", sans-serif;
}

.new-chart {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
} 
form {
  margin-top: 50px;
  padding: 30px 20px;
  width: 40%;
  border-radius: 7px;
  backdrop-filter: blur(5px);
  /* background-color:  #023365;;  */
  overflow: hidden;
}
.form-container{
  width:auto;
  display: flex;
  justify-content: center;
}

.chart-container{

  display: flex;
  justify-content: center;
 
}
.chart{
  width:50%;
}
.solar-summary {
  padding-left: 15%;
}

.chart-summary {
  padding-right: 15%;
}

/* .chart-info{
  font-family: 'Roboto', 'Arial', sans-serif; 
} */

.transit-chart{
  width: 100%;
  height: auto;
  /* display: flex;
  justify-content: center */

} 



/* .newForm{
  padding: 5px 7px;
  margin: 3px 8px 16px 8px;
  background-color: rgba(222, 239, 248, 0.877);
  background-color: rgba(255,255,255,1);
  border: 0px transparent;
  border-radius: 5px;
  color: rgb(97, 4, 184);
  font-size: 16px;
  z-index: 1;
}  */

textarea {
  padding: 8px 10px;
  margin: 3px 8px 16px 8px;
  background-color: rgba(222, 239, 248, 0.877);
  border: 0px transparent;
  border-radius: 5px;
  color: rgb(97, 4, 184);
  font-size: 16px;
  z-index: 1;
}


.submitForm:hover {
  cursor: pointer;
}

/*To fix autofill that applies a background to input (an no other field!) in Chrome since 2019 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}