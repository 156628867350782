.arrow-controls{

    display: flex;
    justify-content: center;
    /*height:2.25rem;  min height of Joy inputfield 2.25rem */
    padding: 10px 0px 10px 0px;
}
.arrow-back-group, .arrow-forward-group{
    display: flex;
    align-items: center;

}
/* .timefield{
    display:flex;
} */
.button-name{
    color:rgba(0, 0, 0, 0.6);
    font-family:"Roboto","Helvetica","Arial",sans-serif;
    font-weight:400;
    font-size:0.75rem;
    line-height:1.4375em;
    letter-spacing:0.00938em;
    padding:0;
    position:relative;
    display:block;
    padding-left: 5px;
}
.increment-button-group{
    display: flex;
    justify-content: flex-start;
    padding-bottom: 2px;

    
}
.decrement-button-group{
    display: flex;
    justify-content: flex-start;
    padding-top: 2px;
    
}
.increment-date-button-group{
    width: 38%;
    
}
.increment-time-button-group{
    width: 50%;
    display: flex;
    
}
.increment-hour, .increment-minute, .decrement-hour, .decrement-minute{
    padding: 4px 4px 0px 4px;
}
