/* .chart{
    border: solid 1px red;
}  */

.planet-ring{
    font-size:2rem;
    color:rgb(255, 255, 255); 
    /*color: rgba(23,38,80,1);*/
  
    /* stroke:rgba(23,38,80,1);    */
    stroke: rgb(7, 80, 164);   
    /* stroke: rgba(240,180,0,1); */
    stroke-width: 1px;
}
.sign-ring{
    /* color: rgba(188,217,239,1); 
     color: rgba(128,184,231,1);    */
    fill: rgba(246, 224, 157, 0.1);
    fill: rgb(243,251,252,1);
    stroke: rgb(7, 54, 164);
    stroke-width: 3px;     
    /* color: rgba(7, 80, 164,1);
    color: rgba(236, 96, 36, 1);      */
}
.sign-ring__content{
    /* font-family: 'ASTRO', sans-serif; */
    font-family: 'Times New Roman', sans-serif;                         
    font-size: 1.75rem;
    /*color:rgba(23,38,80,1); */
    color:rgb(255, 255, 255);
    color: rgba(23,38,80,1);  
}
.house-ring__content{
    font-size: 1.65rem;
    font-size: 1.35rem;
    font-family: "Roboto Serif", 'Times', serif;
    font-family: 'Libre Franklin', serif; 
    color: rgb(255,255,255,1)
}
/* #house{
    transform:
        rotate(20deg) 
        translate(50,90);
} */
.planet-ring__planet{
    font-family: 'ASTRO', sans-serif;
    font-size: 3rem;
    stroke-width: 0.25px;                    

} 
 .planet-ring__planet--inner{
    font-family: 'ASTRO', sans-serif;                     
    font-size: 2rem;
    stroke-width: 0.25px;
}

.center-ring{ 
    color: rgba(23,38,80,1);
    color: rgba(243,251,252,1);          

}
.house-ring{
    font-size:1rem;
    /* color: rgba(255,255,255,1);
    stroke:rgba(23,38,80,1);    */
    stroke: rgb(7, 80, 164);   
    color:rgb(254, 241, 140);
    color: transparent;
    color: rgb(247,242,198);
    fill: rgba(255,251,238,1);
    fill: rgba(76,98,149,1);

    /* color:rgba(23,38,80,1);    
    color: rgba(128,184,231,1);   */
}
.axes{

    stroke: rgb(33, 67, 146); 
    stroke-width: 1px;
}
.house-axes{

    stroke: rgb(255,255,255); 
    stroke: rgb(243,251,252,1);
    stroke-width: 1px;
}


.planet-ring__content, .planet-ring__content--outer {
    font-family: Arial, Helvetica, sans-serif;  
    font-family: "Roboto Serif", 'Times', serif; 
    font-family: 'Libre Franklin', serif;
    font-size: 1rem;                  
    font-size: 1.50rem;
    font-weight: 400;
    color: rgba(158,196,240,1);
    color:rgba(114,155,196,1);
    color: rgba(23,38,80,1);
    transform-box: fill-box;  
} 

.planet-ring__content--inner{
    font-family: 'Arial', sans-serif; 
    font-family: "Roboto Serif", 'Times', serif; 
    font-size: 1.75rem;                    
    font-size: 1.40rem;
    font-weight: 400;
    /*color: rgba(158,196,240,1);
    color:rgba(114,155,196,1);*/
    color: rgba(23,38,80,1);
    transform-box: fill-box;  
}
 .planet-ring--inner{
    color: transparent;
    stroke:rgba(23,38,80,1);   
    stroke-width: 1px;
} 
.house-ring--biwheel{
    /* color: rgba(188,217,239,1); */
    color:transparent;
    /* stroke:rgba(23,38,80,1);    */
    /*stroke: rgba(128,184,231,1); */
    stroke: rgba(240,180,0,1);  
    stroke: rgba(208, 44, 16,1);
    stroke: rgb(7, 80, 164);   
    fill: rgba(246, 224, 157, 0.5);

} 
.hover-house:hover{
    stroke: rgba(247, 217, 244, 0.7);
    /* stroke: rgba(7, 80, 164,0.8);    */
    stroke-width: 2px; 
    fill: rgba(251, 43, 255, 0.05);
    /* visibility: visible; */
}
.hover-house{
    stroke: transparent;
    fill:transparent
    /* fill: rgba(172,0,158,0.15); */

}

.hover-house-test{
    stroke: transparent;
    fill: rgba(251, 43, 255, 1);
    /* fill: rgba(172,0,158,0.15); */

} 
.hover-chart-border:hover{
    stroke: rgb(7, 54, 164);
} 
.hover-chart-border{
    fill:transparent;
    stroke-width: 10px;
    stroke: transparent;
}

/* 
.stars{
    fill: rgba(255,255,255,1);
    stroke:rgba(255,255,255,1); 

}
.moon{
    fill: rgba(255,255,255,1);
}
.starburst{
    stroke-width: 1.5px;
    stroke: rgb(7, 80, 164);   
    stroke: rgb(249, 241, 183);  
    stroke: rgb(255, 255, 255); 

} */
.sign-ring__content{
    font-family: 'ASTRO', sans-serif;                     
    font-size: 2.25rem;
    /* color: rgba(198,64,6, 1);  
    color: rgba(200,70,19);
    color:rgba(200,19,119,1);
    color: rgb(7, 54, 164);   */
    color:rgba(23,38,80,1);  

}
.planet-ring__sign{
    font-family: 'ASTRO', sans-serif;                     
    font-size: 2rem;
    font-weight:200;
    /* stroke:rgba(23,38,80,1);   */
}
.planet-ring__sign--inner{  
    font-family: 'ASTRO', sans-serif;                     
    font-size: 1.5rem;
    font-weight:200; 
    /* stroke:rgba(23,38,80,1);   */
}
.Sag{
    font-size: 2.5rem;
}
.Me{
    font-size: 3.25rem;
}
.planet-ring__planet rx{
    font-size: 2rem;
}

.Su, .Sag{
    color: rgb(247,178,0);

}
.Ma, .Sco, .Cap{
    color: rgb(208,0,30); 
}
.Ju, .Me, .Can{
    color: rgb(163,12,222);
}
.Ne, .Ve, .Pis{
    color:rgb(2,171,174);
}
.Pl{
    color: rgb(0,0,0);
}
.Mo, .Ari, .Aqu{
    color:rgb(40,112,194);
}
.Ra, .Ke{
    color:rgb(66,76,79);
}
.Tau, .Ur{
    color:rgb(149,70,2);
}
.Lib, .Leo{
    color:rgb(213,185,0);
}
.Sa,.Vir{
    color:rgb(64,0,120);

}
.Gem{
    color:rgb(7, 80, 164);  
}
.DSC{
    font-family: "Roboto Serif", 'Times', serif;                    
    font-size: 1.5rem;
}
.rx{
    color:rgb(255, 0, 0);  
}
@font-face {
    font-family: 'ASTRO';
    src: local('ASTRO');
    src: url(../fonts/Astro/AstroFont.ttf);
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

.libre-franklin-chart {
    font-family: 'Libre Franklin', serif;
    font-size: 1rem;

  }

  @font-face {
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/librefranklin/v18/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhLsWkANDJTedX18mE.woff2) format('woff2');
  }
